<script>

    import {getContext, createEventDispatcher, onMount} from 'svelte';
    import { formatLoanTerm } from "../../Data/Utils/utils";
    import feeTypes from "../../Data/Lists/feeTypes";
    import {Card, CardBody, Image} from "sveltestrap";

    // Dispatcher
    const dispatch = createEventDispatcher();

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Passed Vars
    export let product= null;

    // Computed Properties
    $: loanAmount = product.loanAmount || 0;
    $: term = product.rates && product.rates.length ? product.rates.reduce((a, b) => a + b.term, 0) : null;
    $: initialMonthlyPayment = product.rates && product.rates.length ? product.rates[0].monthlyRepayment : null;
    $: initialRate = product.rates && product.rates.length ? product.rates[0].rate : null;
    $: productName = product.name && product.name.length ? product.name : "N/A";
    $: aprc = product.aprc;
    $: fees = product.fees.length ? product.fees.reduce((a, b) => a + b.amount, 0) : 0;
    $: totalRepayable = product.rates.length ? product.rates.reduce((a,b) => a + (b.monthlyRepayment * b.term), 0) : 0;
    $: netLoanInterest = totalRepayable - loanAmount - fees;
    $: onlyOneAndFixed = product.rates.length === 1 && product.rates[0].type === "Fixed";

    $: repPayments = product.rates.length ? product.rates.map((rate) => {
        return `${rate.term} instalments of £${rate.monthlyRepayment.toFixed(2)} at ${rate.rate.toFixed(2)}% (${rate.type})`
    }).join(", followed by ") : '';

    $: repExample = `Borrowing £${loanAmount.toLocaleString("en-GB", {minimumFractionDigits: 0,maximumFractionDigits: 0})} over ${term} months. You will pay
    ${repPayments}. Total amount payable of £${totalRepayable.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    This includes a lender fee of £${fees.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})} and the
    net loan interest of £${netLoanInterest.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    The overall cost for comparison is ${aprc}% APRC ${onlyOneAndFixed ? '' : 'Variable'}.`;

</script>

<div class="results__product__item p-3 shadow">
    <h3>Borrowing  <span class="text-success">&pound;{
        loanAmount.toLocaleString("en-GB", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0})
    }</span> over <span class="text-success">{ formatLoanTerm(term) }</span> from <span class="text-success">{ product.lender }</span></h3>
    <p>Product: { product.name }</p>
    <div class="results__product__item__details align-items-stretch justify-content-stretch mw-100">
        <div class="d-flex flex-column align-items-center justify-content-center bg-light rounded px-1 px-md-2 py-0 py-md-1">
            <div class="rounded mx-auto mh-100" style="min-width:125px;">
                <Image fluid class="rounded shadow-sm" src={product.imageUrl} alt={product.lender} />
            </div>
        </div>
        <div class="d-flex flex-column py-2 r justify-content-center bg-primary bg-gradient text-white shadow-sm rounded px-2 py-1">
            <span>Initial Monthly Payment</span>
            <h4 class="m-0 p-0">
                &pound;{
                initialMonthlyPayment.toLocaleString("en-GB", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
            </h4>
        </div>
        <div class="d-flex flex-column py-2 justify-content-center rounded px-2 py-1">
            <span>Initial Interest Rate</span>
            <h4 class="m-0 p-0">
                {
                    initialRate.toLocaleString("en-GB", {
                        maximumFractionDigits: 2
                    })
                }%
            </h4>
        </div>
        <div class="d-flex flex-column py-2 justify-content-center rounded px-2 py-1">
            <span>Total Repayable</span>
            <h4 class="m-0 p-0">&pound;{totalRepayable.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2})}</h4>
        </div>
        <div class="d-flex flex-column py-2 justify-content-center rounded px-2 py-1">
            <span>Fee</span>
            <h4 class="m-0 p-0">&pound;{fees.toLocaleString("en-GB", { maximumFractionDigits: 2})}</h4>
        </div>
        <div class="d-flex flex-column py-2 justify-content-center rounded px-2 py-1">
            <span>APRC</span>
            <h4 class="m-0 p-0">
                {
                    aprc.toLocaleString("en-GB", {
                        maximumFractionDigits: 2
                    })
                }%
            </h4>
        </div>
    </div>
    <div class="pt-2 d-flex flex-column">
        <slot/>
        <p class="small m-0">{ repExample }</p>
    </div>
</div>

<style>
.results__product__item__details {
    display:grid;
    gap: 0.5rem;
    grid-template-columns: repeat(6, 1fr);
}
/* less than 1200px */
@media (max-width: 1200px) {
    .results__product__item__details {
        grid-template-columns: repeat(3, 1fr);
    }
}
/* less than 768px */
@media (max-width: 768px) {
    .results__product__item__details {
        grid-template-columns: repeat(2, 1fr);
    }
}</style>
