<script>
    import {createEventDispatcher} from "svelte";
    const dispatch = createEventDispatcher();

    import {Row, Col, FormGroup, Input,  Button, Icon} from "sveltestrap";
    import {
        filters
    } from "../../Data/Tool/toolStore";
    import productTypes from "../../Data/Lists/productTypes";
    import { fixedRates } from "../../Data/Lists/productTypes";

    function update() {
        dispatch('update');
    }

    function info() {
        dispatch('info');
    }

</script>

<div class="`w-100 shadow-sm">
    <div class="bg-light p-2 d-flex align-items-center">
        <span class="fw-bold">Filter Results</span>
        <Button color="link" class="p-0 ms-2" on:click={info}>
            <Icon name="info-circle-fill fs-5" />
        </Button>
    </div>
    <div class="p-2">
        <Row class="g-2">
            <Col xs="12">
                <p class="mb-1">Would you like to look at a fixed rate or variable rate?</p>
                <FormGroup class="mb-0 w-100 radio-grid">
                    {#each productTypes as type}
                        <Input
                                class={`btn ${$filters.rateType === type.value ? 'btn-primary' : 'btn-outline-primary'}`}
                                type="radio"
                                id={`rateType-${type.value ?? 'null'}`}
                                value={type.value}
                                label={type.label}
                                bind:group={$filters.rateType}
                        />
                    {/each}
                </FormGroup>
            </Col>
            {#if $filters.rateType === 'fixed' || $filters.rateType === 'tracker'}
                <Col xs="12">
                    <p class="mb-1">How long would you like to fix the rate for?</p>
                    <FormGroup class="mb-0 w-100 radio-grid">
                        {#each fixedRates as rate}
                            <Input
                                    class={`btn ${$filters.fixedType === rate.value ? 'btn-primary' : 'btn-outline-primary'}`}
                                    type="radio"
                                    id={`fixedType-${rate.value ?? 'null'}`}
                                    value={rate.value}
                                    label={rate.label}
                                    bind:group={$filters.fixedType}
                            />
                        {/each}
                    </FormGroup>
                </Col>
            {/if}
        </Row>
    </div>
</div>

<style>
:global(.radio-grid) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
:global(.radio-grid .form-check .form-check-label) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
:global(.radio-grid .form-check input) {
        visibility: hidden;
}</style>