<script>

    import {onMount, tick} from "svelte";
    import {Button, FormGroup, Input} from "sveltestrap";
    import {createEventDispatcher} from "svelte";

    let dispatch = createEventDispatcher();

    onMount(() => {
        let loqateStyleSheet = document.createElement("link");
        loqateStyleSheet.setAttribute("rel", "stylesheet");
        loqateStyleSheet.setAttribute("type", "text/css");
        loqateStyleSheet.setAttribute(
            "href",
            "https://services.postcodeanywhere.co.uk/css/address-3.91.css"
        );
        document.head.appendChild(loqateStyleSheet);
        let loqateScript = document.createElement("script");
        loqateScript.setAttribute(
            "src",
            "https://services.postcodeanywhere.co.uk/js/address-3.91.js"
        );
        document.head.appendChild(loqateScript);
        loqateScript.addEventListener("load", () => {
            let fields = [
                { element: `click-ui-loqate-postcode-${name}`, field: "" }
            ];
            let options = {
                key: "TN68-NW59-BN26-KY31",
                countries: { codesList: "GBR" }
            };
            loqateControl[name] = new window.pca.Address(
                fields,
                options
            );

            loqateControl[name].listen("populate", address => {
                data.errors.search = undefined;
                loqateControl[name].destroy();
                data.addressSelected = true;
                data.values.search = undefined;
                data.values.loqateAddressObject = address;
                data.values.postcode = address.PostalCode;
                data.values.building =
                    address.BuildingNumber ||
                    (address.SubBuilding
                        ? `${address.SubBuilding}, ${address.BuildingName}`
                        : address.BuildingName);
                data.values.street = address.Street;
                data.values.district = address.District;
                data.values.town = address.City;
                data.values.county = address.ProvinceName;
                data.values.udprn = `${address.DomesticId}.00`;
                data.values.addressLabel = address.Label;
                value = data.values;
            });
        });
    });

    const searchRules = Object.freeze([
        value =>
            typeof value === "undefined"
                ? "Please enter an address"
                : "Please select an address"
    ]);

    let loqateControl = {};

    //
    // Props
    //
    export let name = "loqateAddressFinder";
    export let believe = true;
    export let value = {};
    export let dirty = false;

    //
    // Watcher
    //
    $: {
        if (typeof value !== "undefined") {
            data.values = {
                ...data.values,
                ...value,
            };
            data.addressSelected = Object.keys(value).length > 0;
        }
    }

    //
    // Refs
    //
    let postcode;

    //
    // Data
    //
    let data = {
        validation: searchRules,
        values: {
            search: undefined,
            postcode: undefined,
            udprn: undefined,
            building: undefined,
            street: undefined,
            district: undefined,
            town: undefined,
            county: undefined,
            loqateAddressObject: undefined,
            addressLabel: undefined,
            ...value
        },
        errors: {
            postcode: undefined,
            search: undefined,
            building: undefined,
            street: undefined,
            town: undefined
        },
        postcodeDirty: false,
        addressSelected: false
    };

    //
    // Computed
    //
    $: valid = !Object.values(data.errors).some(e => typeof e !== "undefined");

    //
    // Methods
    //
    function searchAddressAgain() {
        value = {};
        data.addressSelected = false;
        loqateControl[name].load();
        setTimeout(() => {
            let els = document.querySelectorAll(`#click-ui-loqate-postcode-${name}`);
            if (els != null && els.length > 0) els[0].focus();
        }, 100);

    }

</script>

<div class="click-ui-loqate-address-finder">
    <div class="click-ui-loqate-address-finder__postcode">
        <FormGroup
                class={`${ data.addressSelected === false ? 'd-block' : 'd-none' }`}
                floating
                label="Address"
                dirty={dirty || data.postcodeDirty}
                invalid={data.errors.search}>
            <Input
                id={`click-ui-loqate-postcode-${name}`}
                bsSize="lg"
                type="text"
                bind:value={data.values.search}
                placeholder="Start typing your address to search..."
                bind:this={postcode}
                class="click-ui-input__input-loqate-postcode"
            />
        </FormGroup>
        <div class={`${ data.addressSelected !== false ? 'd-block' : 'd-none' }`}>
            <p class="click-ui-loqate-address-finder__postcode__address-text">{@html data.values.addressLabel ? data.values.addressLabel.split('\n').join('<br/>') : 'N/A'}</p>
            <Button
                    on:click={searchAddressAgain}
                    believe={believe}>Search again</Button>
        </div>
    </div>
</div>

<style>
:global(.pca .pcaautocomplete.pcatext) {
    font-family: "athletics_regular", Arial, sans-serif;
    font-size:1em !important;
}
:global(.pca .pcaautocomplete .pcaitem) {
    padding: 0.75rem 0.35rem;
}</style>