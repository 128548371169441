<script>
  import {onMount, createEventDispatcher} from "svelte"
  import { createWidget } from '@typeform/embed'

  const dispatch = createEventDispatcher();

  // Props
  export let formId = "";
  export let accessCode = "";

  let formHeight = 500;
  
  onMount(() => {
      createWidget(formId, {
          container: document.querySelector('#type-form'),
          hideHeaders: true,
          hideFooter: true,
          opacity: 0,
          hidden: {
              access_code: accessCode
          },
          onHeightChanged: (event) => {
              formHeight = event.height;
          },
          onSubmit: async (event) => {
              dispatch('completed', event.response_id);
          },
          onEndingButtonClick: async (event) => {
              dispatch('finished', event);
          }
      })
  })
</script>
<div id="type-form" style={`height:${formHeight}px`}></div>
<style>
@import '../../../../node_modules/@typeform/embed/build/css/widget.css';
:global(.tf-v1-widget) {
    height: 100%;
}
:global(.tf-v1-widget iframe) {
    width: 100%;
    border: none;
    height: 100%;
}
:global(.tf-v1-widget-close) {
    display: none;
}</style>