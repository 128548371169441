<script>
    import {onMount} from "svelte";
    import {router, Route, meta} from "tinro";
    import {Image, Navbar, NavbarBrand, Styles} from 'sveltestrap';

    import {activePortal, defaultPortal, portals} from "./Shared/Data/Portal/portalStore";

    import Loading from "./Views/Loading/Loading.svelte";
    import NotFound from "./Views/NotFound.svelte";
    import Home from "./Views/Home/Home.svelte";

    import Portal from "./Portal.svelte";
    import GetStarted from "./Views/Quote/GetStarted.svelte";
    import InitialQuotes from "./Views/Quote/Quotes.svelte";
    import Proceed from "./Views/Quote/Proceed.svelte";
    import Submit from "./Views/Quote/Processing.svelte";
    import Income from "./Views/Quote/Income.svelte";
    import Expenditure from "./Views/Quote/Expenditure.svelte";
    import ThankYou from "./Views/Quote/ThankYou.svelte";
    import Consolidation from "./Views/Quote/Consolidation.svelte";
    import ProductTransfer from "./Views/Quote/ProductTransfer.svelte";
    import Callback from "./Views/Quote/Callback.svelte";
    import Identity from "./Views/Quote/Identity.svelte";

    let currentPath = null;

    //
    // Routes
    //
    router.mode.history();

    let subRoutes = [
        { slug: 'welcome', component: Home},
        { slug: 'get-started', component: GetStarted },
        { slug: 'quotes', component: InitialQuotes },
        { slug: 'proceed', component: Proceed },
        { slug: 'processing', component: Submit},
        { slug: 'consolidation', component: Consolidation, props: { tokenRequired: true } },
        { slug: 'product-transfer', component: ProductTransfer, props: { tokenRequired: true } },
        { slug: 'identity', component: Identity, props: { tokenRequired: true } },
        { slug: 'income', component: Income, props: { tokenRequired: true } },
        { slug: 'expenditure', component: Expenditure, props: { tokenRequired: true } },
        { slug: 'callback', component: Callback, props: { tokenRequired: true } },
        { slug: 'thank-you', component: ThankYou }
    ];

    let routes = [];

    async function generateRoutes() {
        let all = (await portals()).all;
        routes = all.map(p => {
            return subRoutes.map(x => {
                let slug = p.term.toLowerCase();
                if (p.api === 'specialist-finance') {
                    slug = 'specialist';
                }
                return {
                    path: `/${slug}/${x.slug}/`,
                    component: x.component,
                    props: {
                        portal: p.api,
                        ...x.props
                    },
                }
            })
        }) || [];
    }

    onMount(async () => {
        if (!true) {
            console.log("Development Mode");
        }
        router.subscribe((x) => {
            currentPath = x.path;
            window.scrollTo(0, 0);
        });
        await generateRoutes();
    })

    let portal = defaultPortal.standard;
    activePortal.subscribe(c => {
        portal = c ? c : defaultPortal.standard;
    });

    $: trustPilotHeader = portal && portal.trustPilot && portal.trustPilot.businessUnitId && currentPath && currentPath.startsWith("/quote");
    $: homePage = portal === defaultPortal.standard ? "/" : `/${portal.term ? portal.term.toLowerCase() : ''}/welcome/`;

</script>

<svelte:head>
    {#if trustPilotHeader}
        <!-- TrustBox widget - Micro Star -->
        <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="{ portal.trustPilot.businessUnitId }" data-style-height="24px" data-style-width="268px" data-theme="light">
          <a href="https://uk.trustpilot.com/review/{ portal.brand.domain }" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
    {/if}
    <Styles/>

    {#if portal.api === 'first-charge'}
        <style>
:root{
    --bs-primary-rgb: 254, 76, 0 !important;
    --bs-primary: #FE4C00 !important;
    --bs-primary-dark: #c43a00 !important;
}</style>
    {/if}

    {#if portal.api === 'second-charge'}
        <style>
:root{
    --bs-primary-rgb: 24, 173, 175 !important;
    --bs-primary: #18adaf !important;
    --bs-primary-dark: #0f7c7e !important;
}</style>
    {/if}

    {#if portal.api === 'specialist-finance'}
        <style>
:root{
    --bs-primary-rgb: 92, 79, 160 !important;
    --bs-primary: #5C4FA0 !important;
    --bs-primary-dark: #3a2f6e !important;
}</style>
    {/if}

</svelte:head>

<Navbar color="light" light class="border-bottom">
    <NavbarBrand href={homePage}>
        <Image src="/{ portal.brand.logo }" alt="{ portal.brand.name }" style="max-height: 75px;" />
    </NavbarBrand>
</Navbar>

<Route>
    <Route path="/">
        <Home />
    </Route>
    {#each routes as route}
        {#each route as r}
            <Route path="{`${r.path}:accessCode`}" let:params>
                <Portal {...r.props} accessCode={params.accessCode}>
                    <svelte:component this="{ r.component }" />
                </Portal>
            </Route>
            <Route path="{ r.path }">
                <Portal  {...r.props} >
                    <svelte:component this="{ r.component }" />
                </Portal>
            </Route>
        {/each}
    {/each}
    {#if routes.length}
        <Route fallback>
            <NotFound/>
        </Route>
    {:else}
        <Route fallback>
            <Loading/>
        </Route>
    {/if}
</Route>

<style>
:global(html, body) {
    padding:0 !important;
    margin:0 !important;
    min-width: 375px !important;
    min-height: 100%;
    height: 100%;
}
:global(body) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
:global(.form-floating.mb-0) {
    margin-bottom: 0 !important;
}
:global(.form-select.form-control-lg) {
    font-size:1.25rem;
}
:global(.btn-link) {
    --bs-btn-color: var(--bs-primary) !important;
    --bs-btn-hover-color: var(--bs-primary-dark) !important;
}
:global(.btn-link:active) {
    color: var(--bs-primary-dark) !important;
}
:global(.btn-outline-primary) {
    --bs-btn-color: var(--bs-primary) !important;
    --bs-btn-border-color: var(--bs-primary) !important;
    --bs-btn-hover-border-color: var(--bs-primary) !important;
    --bs-btn-hover-bg: var(--bs-primary-dark) !important;
}
:global(.btn-primary) {
    --bs-btn-bg: var(--bs-primary) !important;
    --bs-btn-border-color: var(--bs-primary) !important;
    --bs-btn-hover-border-color: var(--bs-primary) !important;
    --bs-btn-hover-bg: var(--bs-primary-dark) !important;
}
:global(.btn-primary:active, .btn-outline-primary:active) {
    background-color: var(--bs-primary-dark) !important;
    border-color: var(--bs-primary) !important;
}
:global(.form-check-input:checked[type=radio]) {
    background-color: var(--bs-primary-dark) !important;
    border-color: var(--bs-primary-dark) !important;
}
:global(.btn-check:checked+.btn) {
    --bs-btn-active-bg: var(--bs-primary) !important;
    --bs-btn-border-color: var(--bs-primary) !important;
    --bs-btn-active-border-color: var(--bs-primary) !important;
}
:global(.dropdown-item.active, .dropdown-item:active) {
    background-color: var(--bs-primary) !important;
}
:global(.pagination) {
    --bs-pagination-color: var(--bs-primary) !important;
    --bs-pagination-active-bg: var(--bs-primary) !important;
    --bs-pagination-active-border-color: var(--bs-primary) !important;
    --bs-pagination-hover-color: var(--bs-primary-dark) !important;
}</style>
